import React from "react";
// import Routes from "../routes";
import Sidebar from "../../pages/Sidebar/Sidebar.component";

function Layout(props) {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <Sidebar 
        role={props.role}
        history={props.history} />
        <div style={{   width:'70%', margin:'0px auto', marginTop:'3rem' }}>
          {props.children}
        </div>
      </div>
    </div>
    // <Sidebar>{props.children}</Sidebar>
  );
}

export default Layout;
