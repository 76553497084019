import "./Sidebar.styles.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import {SidebarUserItems,SidebarAdminItems} from "./SidebarItems";
import SidebarItems from "./SidebarItems";
import { Link, useLocation } from "react-router-dom";
import AddSubsModal from "../Admin/AddSubsModal/AddSubsModal";
import { useNavigate } from "react-router-dom";
import { User_Api_Url } from "../../URL/url";
import axios from "axios";
import TableRowsIcon from "@mui/icons-material/TableRows";
import UpdatePassword from "../Admin/UpdateAdminPassword/UpdatePassword";
import UpdateSubscription from "../User/UpdateSubscription/UpdateSubscription";
import UpdateUserPassword from "../User/UpdateUserPassword/UpdateUserPassword";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from '@mui/icons-material/Lock';

function Sidebar(props, { defaultActive }) {
  let navigate = useNavigate();
  const location = useLocation();
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
  const lastActiveIndex = Number(lastActiveIndexString);
  const [sidebarCheck, setSidebarCheck] = useState(false);

  const [addSubsModalOpen, setAddSubModelOpen] = useState(false);
  const [PasswordModelOpen, setPasswordModelOpen] = useState(false);
  const [userSubModelOpen, setUserSubModelOpen] = useState(false);
  const [UserPasswordModelOpen, setUserPasswordModelOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive
  );

  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex);
    setActiveIndex(newIndex);
  }

  const handleLogout = () => {
    let myToken = localStorage.getItem("token");
    console.log(myToken, "here is my token");
    localStorage.clear();
    axios.post(User_Api_Url + "/signout", { token: myToken }).then((resp) => {
      console.log(resp, "here is my response of logout");
      navigate("/");
    });
  };

  function getPath(path) {
    if (path.charAt(0) !== "/") {
      return "/" + path;
    }
    return path;
  }

  const handleAdminPasswordClose = () => setPasswordModelOpen(false);
  const handelUpdatePasswordModelOpen = () => setPasswordModelOpen(true);

  const handleUserPasswordClose = () => setUserPasswordModelOpen(false);
  const handleUserPasswordModalOpen = () => setUserPasswordModelOpen(true);

  const handleSubsModalClose = () => setUserSubModelOpen(false);
  const handleSubsModalOpen = () => setUserSubModelOpen(true);

  const handleAddSubsModalClose = () => setAddSubModelOpen(false);
  const handleAddSubsModalOpen = () => setAddSubModelOpen(true);

  useEffect(() => {
    const activeItem = SidebarItems.findIndex(
      (item) => getPath(item.route) === getPath(location.pathname)
    );
    changeActiveIndex(activeItem);
  }, [location]);

  const handleSidebar = () => {
    setSidebarCheck(!sidebarCheck);
  };

  return (
    <>
      <TableRowsIcon
        className={sidebarCheck ? "gridIconColor" : "gridIcon"}
        onClick={handleSidebar}
      />
      <SidebarParent className={sidebarCheck ? "sidebar" : "showSideBar"}>
        <div style={{ position: "fixed", backgroundColor: "rgb(26 29 44)" }}>
          <img
            src="https://icdn.video/sites/all/themes/ctv_theme/logo.png"
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
          {SidebarItems.map((item, index) => {
            const handelSideBarItem = () => {
              if (item.modal === "add_sub") {
                handleAddSubsModalOpen();
              } else if (item.modal === "password_admin") {
                handelUpdatePasswordModelOpen();
              } else if (item.modal === "password_user") {
                handleUserPasswordModalOpen();
              } else if (item.modal === "update_sub") {
                handleSubsModalOpen();
              } else {
                return null;
              }
            };
            if (props.role === item.role) {
              return (
                <>
                  <Link key={index} to={item.route}>
                    {item.modal ? (
                      <>
                        <SidebarItem
                          onClick={handelSideBarItem}
                          key={item.name}
                          active={index === activeIndex}
                        >
                          <div className="sidebar_items">
                            {item.icon}
                            <p>{item.name}</p>
                          </div>
                        </SidebarItem>
                        <UpdateUserPassword
                          UpdateUserPasswordModelOpen={UserPasswordModelOpen}
                          handleUpdateUserPasswordClose={
                            handleUserPasswordClose
                          }
                        />
                        <UpdatePassword
                          UpdatePasswordModelOpen={PasswordModelOpen}
                          handleUpdatePasswordClose={handleAdminPasswordClose}
                        />
                        <UpdateSubscription
                          updateSubModelOpen={userSubModelOpen}
                          handleUpdateSubClose={handleSubsModalClose}
                        />
                        <AddSubsModal
                          subsModalOpen={addSubsModalOpen}
                          handleSubsModalClose={handleAddSubsModalClose}
                        />
                      </>
                    ) : (
                      <SidebarItem
                        key={item.name}
                        active={index === activeIndex}
                      >
                        <div className="sidebar_items">
                          {item.icon}
                          <p>{item.name}</p>
                        </div>
                      </SidebarItem>
                    )}
                  </Link>
                </>
              );
            } else {
              <></>;
            }
          })}
          <div onClick={handleLogout}>
            <SidebarItem>
              <div className="sidebar_items">
                <LogoutIcon fontSize="small"  color="error"/>
                <p>Logout</p>
              </div>
            </SidebarItem>
          </div>
        </div>
        <div className="behind-the-scenes" />
      </SidebarParent>
    </>
  );
}

export default Sidebar;

const SidebarParent = styled.div`
  background: rgb(217 220 239);

  a {
    text-decoration: none;
  }

  & > div {
    width: 250px;
    height: 100vh;
  }

  .behind-the-scenes {
    width: 250px;
  }
`;

const SidebarItem = styled.div`
  margin-top: 10px;
  padding: 10px 18px;
  transition: all 0.25s ease-in-out;
  background: ${(props) => (props.active ? "#6c96fb" : "")};

  border-radius: 4px;
  p {
    color: white;
    font-weight: bold;
    text-decoration: none;
    padding-top: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:not(:first-child) {
    background: #c34a36;
  }
`;
