import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { Device_Api_Url } from "../../URL/url";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import addToken from "../Login/addToken";


function Page1(props) {
  const [userDeviceList, setUserDeviceList] = useState([])
  const [user] = useState(false);
  const getDeviceList = () =>{
    const userId = localStorage.getItem('userId')
    axios.get(Device_Api_Url+'device/deviceList/'+ userId,addToken()).then((resp)=>{
      const mapData = resp.data.map(items=>{
          return {
            Device_Name: items.deviceName,
            Device_IP: items.ipAddress,
            Device_Details: items.deviceDescription,
            Device_Logins: items.numberOfLogin ,
            Device_id: items?._id
          }
       })
       console.log(mapData,"map")
       if(!user){
         setUserDeviceList(mapData)
       }
  })
  }
  useEffect(()=>{
    getDeviceList()
  },[])

  const columns = [
    {name: "sl",
     label: "Serial No.",
     options: {
      filter: true,
      sort: false,
    }
    },
    {
      name: "Device_Name",
      label: "Device Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_Details",
      label: "Device Details",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_IP",
      label: "Device Ip",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Delete",
      label: "Delete",
      options: {
			  filter: true,
        sort: false,
			  customBodyRender: (value, tableMeta, updateValue) => {
				return (
            <IconButton onClick={()=>{
              axios.delete(`${Device_Api_Url}/device/${userDeviceList[tableMeta.rowIndex].Device_id}`,addToken()).then(()=>{
                toast.success('Device deleted successfully!');
                getDeviceList()
              }).catch((error)=>{
                toast.error(`Something wrong 404`);
              })
            }} color="error" aria-label="edit Subscription">
              <DeleteIcon />
            </IconButton>
				);
			  }
			},
    },
    // {
    //   name: "Device_Logins",
    //   label: "Device Logins",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // },

  ];
  // const data = [
  //   { Device_Name: "Joe James", Device_Details: "Test Corp", Device_IP: "Yonkers", Device_Logins: "NY" },
  //   { Device_Name: "John Walsh", Device_Details: "Test Corp", Device_IP: "Hartford", Device_Logins: "CT" },
  //   { Device_Name: "Bob Herm", Device_Details: "Test Corp", Device_IP: "Tampa", Device_Logins: "FL" },
  //   { Device_Name: "James Houston", Device_Details: "Test Corp", Device_IP: "Dallas", Device_Logins: "TX" },
  // ];
  
// Below code will display the  serial No.
  let newData = [];
  userDeviceList.map((item, index) => newData.push({sl: index + 1, ...item}));
 

  const options = {
    setRowProps: (row) => { 
      if (row['&:nth-child(odd)'] === "Serial No.") {
        return {
          style: { 
            backgroundColor: '#FF0000'
          }
        };
      }
    },

    filterType: 'checkbox',
    // selectableRowsOnClick: true,
    selectableRows: 'single' // <===== will turn off checkboxes in rows
  }
  return (<>
    <Layout role="user">
      
      <MUIDataTable
        title={"Devices"}
        data={newData}
        columns={columns}
        options={options}
      
      />
    </Layout>
    <ToastContainer />
    </>
  );
}

export default Page1;
