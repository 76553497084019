import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ Children }) => {
  const navigate =  useNavigate()
  useEffect(()=>{
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    if(!isLoggedIn){
      navigate('/')
    }
  },[])
    return(
      <div>
      <Children/>
      </div>
    )
  
  // return isLoggedIn ? children : <Navigate to="/subscription" />;
};

export default ProtectedRoute;
