import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import "./UpdatePassword.css";
import axios from "axios";
import { TextField } from "@mui/material";
import { Subscription_Api_Url} from "../../../URL/url";
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import addToken from "../../Login/addToken";

const style = {
  display: 'flex',
  // justifyContent: 'space-around',
  alignItems: 'center',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "18px",
  boxShadow: 24,
  p: 4,
  // width: '10px'
};

export default function UpdatePassword(props) {

  const [request, setRequest] = useState({
    password: "",
  })
  const [confirmPassword,setConfirmPassword] = useState("")

  const handleConfirmPassowrd =(e)=>{
    setConfirmPassword(e.target.value)
  }
  const handleChange = (e) =>{
    e.preventDefault()
    const {name,value}=e.target;
    setRequest((request)=>{
      return {
          ...request,[name]:value
      }
    })
  }


  const handleSubmit = (props) => {
    if(confirmPassword.length === 0){
      return toast.error(`Please Enter Password and Confirm Password`);
    }
    if(request.password === confirmPassword){
      axios.post(Subscription_Api_Url+'update-password', request,addToken()).then((resp) => {
          props.handleUpdatePasswordClose();
        }).then(()=>{
          toast.success("Admin Password Updated");
        }).catch((err)=>{
          toast.error(`Something wrong 404`);
        })
    }else{
      toast.error(`Password and Confirm Password are not same.`);
    }
    

  }
  return (
    <div>
      <Modal
        open={props.UpdatePasswordModelOpen}
        onClose={props.handleUpdatePasswordClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="adminContent">
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <div className="subsHeading">Update Password</div>

          <CloseIcon className="crossIcon-btn" onClick={props.handleUpdatePasswordClose} />
            </div>
    
            <form className="selectFields_password">             
              <TextField className="" type="password" id="demo-helper-text-misaligned-no-helper" label="Password" onChange={handleChange} name="password" value={request.password} required/>
              <TextField className="" type="password" id="demo-helper-text-misaligned-no-helper" label="Confirm Password" onChange={handleConfirmPassowrd} name="confirmPassword" value={confirmPassword} required/>
            </form>
            <div className="btnDiv">
              <button className="subsBtn" onClick={() => handleSubmit(props)}>Update</button>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
