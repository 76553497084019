import React ,{useState} from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { Get_User_Device_List, User_Api_Url } from '../../URL/url';

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import { useParams } from 'react-router-dom';
// import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import addToken from '../Login/addToken';

function DeviceList() {
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [block,setBlock] = useState(false)
  let { id } = useParams();
  console.log(id,"sdfghjkl");
  const handleBlock=()=>{
      axios.patch(User_Api_Url + `/block-user`,{userId:id},addToken()).then((resp)=>{
        setBlock(block=> !block)
      })
      
  }

  const handleUnBlock = ()=>{
    setBlock(block=> !block)
  }
  useEffect(() => {
    axios.get(Get_User_Device_List + `device/deviceList/${id}`,addToken()).then((resp) => {
      const mapData = resp.data.map((items) => {
        return {
          Device_Name: items.deviceName ,
          Device_IP: items.ipAddress,
          Login_Count: items.numberOfLogin,
          Device_Details: items.deviceDescription,
          Domain_Name: items.domainName,
          Device_Location: items.location,
          Device_list_id: items?._id,
          Action: (block ? <>
              <BlockIcon onClick={handleUnBlock} stroke="red" />
          </> : <>
          <CheckCircleIcon onClick={handleBlock} color="success"/>
          </>

              
          ),
        };
      });
      setDeviceDetails(mapData);
    });
  }, [id]);
  const columns = [
    {
      name: "Device_Name",
      label: "Device Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Device_Details",
      label: "Device Details",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Device_IP",
      label: "Device IP",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Domain_Name",
      label: "Domain Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Device_Location",
      label: "Device Location",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Login_Count",
      label: "Login Count",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Action",
      label: "Action",
    },
  ];

//   const handleRowClick = (rowData, rowMeta) => {
// };
const options = {
  filterType: "checkbox",
  selectableRows: 'single',
  onRowsDelete: (rowsDeleted, newData) => {
    const finalVal = rowsDeleted.data.map(items=>{
        return deviceDetails[items.index].Device_list_id
    })
    axios.delete(`${Get_User_Device_List}/device/${finalVal.toString()}`,addToken()).then(()=>{
      toast.success('Device deleted successfully!');
    }).catch((error)=>{
      toast.error(`Something wrong 404`);
    })
  },
};

  return (
    <>
    <Layout role="admin">
      <MUIDataTable
        title={`Device List - ${localStorage.getItem('subcription_user')}`}
        data={deviceDetails}
        columns={columns}
        options={options}
      />
    </Layout>
    <ToastContainer />
    </>
  )
}

export default DeviceList;