import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DeviceList from '../pages/Admin/Device_list.component';
import Subscription_list from '../pages/Admin/Subscription_list.component';
import User_List from '../pages/Admin/User_list.component';
import Login from '../pages/Login/Login.component';
import Payment from '../pages/Payment/Payment.component';
import Sidebar from '../pages/Sidebar/Sidebar.component';
import Signup from '../pages/Signup/Signup.component';
import Subscription from '../pages/Subscription/Subscription.component';
// import { Payment } from "@mui/icons-material";
import { allRoutes } from './allRoutes';
import ProtectedRoute from './ProtectedRoute';
import SemiProtected from './SemiProtected';
import Devices from '../pages/User/Devices.component'
import DeviceHistory from '../pages/User/History.component'


function Routess() {
  return (
    <BrowserRouter>
      <Routes>
        {/* {allRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={
              route.isProtected ? (
                <ProtectedRoute>{route.element}</ProtectedRoute>
              ) : route.isSemiProtected ? (
                <SemiProtected>{route.element}</SemiProtected>
              ) : (
                route.element
              )
            }
          />
        ))} */}
        <Route path="/signup" element={<Signup />} />
        <Route path="/payment" element={<ProtectedRoute Children={Payment}/>} />
        <Route path="/" element={<Login />} />
        {/* <Route path="/update_password" element={<Subscription />} /> */}
        <Route path="/subscription" element={<ProtectedRoute Children={Subscription}/>} />
        <Route path="/dashboard" exact element={<Sidebar />} />
        <Route path="/user/devices" element={<ProtectedRoute Children={Devices} />} />
        <Route path="/user/history" element={<ProtectedRoute Children={DeviceHistory} />} />
        <Route path="/admin/user_list" element={<ProtectedRoute Children={User_List} />} />
        <Route path="/admin/user_list/:id" element={<ProtectedRoute Children={DeviceList} />} />
        <Route path="/admin/subscription_List" element={<ProtectedRoute Children={Subscription_list} />} />
        {/* <Route element={<NotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
export default Routess;
