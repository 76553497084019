import './Login.styles.css';
import { ToastContainer, toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { User_Api_Url } from '../../URL/url';
import axios from 'axios';
import swal from 'sweetalert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
// import { w3cwebsocket as W3CWebSocket } from 'websocket';
import io from 'socket.io-client';
import { LensTwoTone } from '@mui/icons-material';
import Swal from 'sweetalert2';
// import { io } from "socket.io-client";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// socket

// const socket = io.connect('http://localhost:3001');

const Login = () => {
  const [open, setOpen] = useState(false);
  const [limitMessage, setLimitMessage] = useState('');
  const [myUserId, setMyUserId] = useState('');
  const [myCheck, setMyCheck] = useState(false);
  const [ip,setIP] = useState("")
  // const [domainName,setDomainName] = useState("")
  let [inputValue, setInputValue] = useState({
    email: '',
    password: '',
    mobile: false,
    tab: false,
    desktop: false,
    ip: "",
    domainName: ""
  });
  let navigate = useNavigate();

  // let [inputValue, setInputValue] = useState({
  //   email: "",
  //   password: "",
  // });

  const [rememberCheck, setRememberCheck] = useState(false);

  // socket
  // var socket = io.connect("http://localhost:4005", {
  //       query: `room=umesh&userId=${myUserId}`
  //   });

  const datafield = (e) => {
    const { name, value } = e.target;
    console.log("value",e.target.value);

    setInputValue({ ...inputValue, [name]: value });
  };

  const getData = async () => {
    const res = await axios.get("https://ipapi.co/json/");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData()
    console.log(inputValue)
    console.log(myCheck, 'here is my check');
    if (myCheck) {
      console.log('incheck');
      axios
        .post(`${User_Api_Url}signin`, {...inputValue, ['ip']: ip})
        .then((resp) => {
          if (resp?.status === 201) {
            setMyUserId(resp.data?.userId);
            setLimitMessage(resp.data.message);
            toast.warn(`${resp.data.message}`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            swal({
              title: `${resp.data.message}`,
              text: 'Forced Logout',
              icon: 'error',
              dangerMode: true,
            }).then(() => {
              handleForceLogout()
              localStorage.clear();
            });
            setOpen(true);
            setMyCheck(false);
          }
          if (resp.data?.data?.isEmailVerified) {
            setMyCheck(false);
            localStorage.setItem('token', resp?.data?.token);
            localStorage.setItem('userId', resp.data?.data._id);
            toast.success('Login Succesfully', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem('isLoggedIn', resp.data?.data?.isLoggedIn);
            if (resp.data?.data?.role.role === 'admin') {
              navigate('/admin/User_list');
            } else {
              // localStorage.setItem('login_user', true)
              localStorage.removeItem('signup_user');
              navigate('/user/devices');
            }
          }

          //     else if(resp.data.code==="400"){
          // console.log(resp?.data,"myresponse");
          // setMyCheck(false);
          // swal({
          //   title: "User Does Not Exist",
          //   text: "Please Relogin",
          //   icon: "error",
          //   dangerMode: true,
          // })
          //     }
        })
        .catch(() => {
          setMyCheck(false);
          swal({
            title: 'Either You Are Blocked Or User Does Not Exist',
            text: 'Please Relogin',
            icon: 'error',
            dangerMode: true,
          });
        });
    }
  }, [myCheck]);

  useEffect(() => {
    function getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }
    setInputValue({
      email: getCookie('loginEmail'),
      password: getCookie('loginPassword'),
    });
  }, []);

  // const optionsState = (e)=>{
  //   console.log("value:",e.target)
  // }

  const handleCheck = (e) => {
    if (e.target.checked) setRememberCheck(true);
    else setRememberCheck(false);
  };

  const handleGetStarted = () => {
    navigate('/signup');
  };

  // const client = new W3CWebSocket('ws://localhost:4005');

  const handleForceLogout = async () => {
    console.log(`${User_Api_Url}/forced-signout/${myUserId}`);
    axios.put(`${User_Api_Url}/forced-signout/${myUserId}`).then((resp) => {
      
      // socket events

      // socket.emit('force_out', myUserId);

      // socket.on("force_logout", (data)=>{
      //   alert("you are going to be log out")
      // });

      console.log('Force logout', resp.data.message);
      toast.success('All User devices logged out successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
      setMyCheck(false);
    });

    console.log(myUserId, 'idddddddd');

    // socket.emit('force-logout', myUserId);
  };

  // useEffect(() => {
  //   socket.on('forced-logout', (data) => {
  //     console.log(data, 'data');
  //     swal({
  //       title: 'Forced Logout from Another User Please Relogin',
  //       text: 'Please Relogin',
  //       icon: 'error',
  //       dangerMode: true,
  //     }).then(() => {
  //       console.log('hiiii');
  //       localStorage.clear();
  //       navigate('/login');
  //     });
  //   });
  // }, [socket]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInputValue({ ...inputValue, ['ip']: ip})
    if (
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      toast.error('Email is not correct', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setMyCheck(false);
    } else {
      if (isMobile) {
        setInputValue({ ...inputValue, ['mobile']: true });
      } else if (isTablet) {
        setInputValue({ ...inputValue, ['tab']: true });
      } else if (isDesktop) {
        setInputValue({ ...inputValue, ['desktop']: true });
      }

      setMyCheck(true);
    }
    //   axios.post(`${User_Api_Url}/signin`,inputValue).then(resp=>{
    // console.log(inputValue,"my input in post");

    //     // console.log(resp,"email check ");
    //     if(resp.data?.message){
    //       setMyUserId(resp.data?.userId);
    //       setLimitMessage(resp.data.message)
    //       toast.warn(`${resp.data.message}`, {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //       setOpen(true)
    //     }
    //     if(resp.data?.data?.isEmailVerified){
    //       localStorage.setItem('userId',resp.data?.data._id)
    //       toast.success("Login Succesfully", {
    //         position: "top-right",
    //         autoClose: 1000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //       localStorage.setItem('isLoggedIn',resp.data?.data?.isLoggedIn)
    //       if(resp.data?.data?.role.role === "admin"){
    //         navigate('/admin/User_list');
    //       }else{
    //         navigate('/user/devices');
    //       }
    //     }
    //     else if(resp.data.code==="201")
    //     swal({
    //       title: "User Does Not Exist",
    //       text: "Please Relogin",
    //       icon: "error",
    //       dangerMode: true,
    //     })
    //   })
    // console.log(rememberCheck,inputValue.email,inputValue.password,"mycreds");
    if (rememberCheck === true) {
      document.cookie = `loginEmail=${inputValue.email} ;path=/`;
      document.cookie = `loginPassword=${inputValue.password} ;path=/`;
    }
  };
  return (
    <div className='Login__container'>
      <div className="imageContainer">
        <img
          src="https://icdn.video/sites/all/themes/ctv_theme/logo.png"
          alt=""
        />
      </div>
      <div className="formContainer">
      <form onSubmit={handleSubmit}>
        <div className="formHeading">
          <h3>Login</h3>
        </div>
        <ToastContainer />
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            onChange={datafield}
            name="email"
            id="exampleInputEmail1"
            placeholder="Enter email"
            value={inputValue.email}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputpassword">Password</label>
          <input
            type="password"
            className="form-control"
            onChange={datafield}
            name="password"
            id="exampleInputpassword"
            placeholder="Enter password"
            value={inputValue.password}
            required
          />
        </div>
        <div className="form-group">
          <label>Select Domain</label><br/>
          <select onChange={datafield} name='domainName' id="selecteDomain">
            <option value="" disabled selected>Select</option>
            <option value="icdn.video">icdn.video</option>
            <option value="zimzim.video">zimzim.video</option>
        </select>
        </div>
        <div className="formCheckBox">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
            />
            <label className="" htmlFor="customCheck1">
              <input
                type="checkbox"
                onChange={handleCheck}
                id="customCheck1"
                style={{ marginTop: '1rem', marginRight: '0.5rem' }}
              />
              Remember me
            </label>
          </div>
        </div>
        <div
          className="g-recaptcha"
          data-sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"
        ></div>
        <div className="formLoginContainer">
          {/* <Link to="/user/devices"> */}
          <button
            type="submit"
            value="Submit"
            className="btn btn-primary formLoginButton"
          >
            LOG IN
          </button>
          {/* </Link> */}
        </div>
        <div className="formForgetPassword">
          <h6>Forgot password</h6>
        </div>
        <div>
          {/* <span>
            <h6 className="login-formField">Still no account</h6>
          </span> */}
          <span>
            <h6 className="login-formField" onClick={handleGetStarted}>Regester</h6>
          </span>
        </div>
      </form>
      </div>
    </div>
  );
};

export default Login;
