import "./Signup.styles.css";
import React, { useState, useMemo } from "react";
import countryList from "react-select-country-list";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {data} from "./CountryData";
import {User_Api_Url} from "../../URL/url";
import OtpModal from "./OtpModal/OtpModal";
import swal from 'sweetalert';
import {isMobile,isTablet,isDesktop } from 'react-device-detect';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import addToken from "../Login/addToken";

const Signup = () => {
  
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState("");

  const [otpModalOpen, setOtpModalOpen] = useState(false);

  const options = useMemo(() => countryList().getData(), []);
  let [inputValue, setinputValue] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "",
    mobile:false,
    tab:false,
    desktop:false
  });

  const changeHandler = (e) => {
    setValue(e.target.value);
    // console.log(e.target.value);
    setinputValue({ ...inputValue, ["country"]: e.target.value });
    if(isMobile){
      setinputValue({...inputValue,["mobile"]:true})
    }
    else if(isTablet){
  
      setinputValue({...inputValue,["tab"]:true});
  
    }
    else if(isDesktop){
  
      setinputValue({...inputValue,["desktop"]:true});
  
    }
  };
    const handleOtpModalClose = () => setOtpModalOpen(false);

  const datafield = (e) => {
    setErrorMessage("")
    const { name, value } = e.target;
    setinputValue({ ...inputValue, [name]: value.trim() });
    if(name==="email"){

      localStorage.setItem("localEmail", value.trim());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !inputValue.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/
      )
    ){
      toast.error(
        "Password must 8 digit long, 1 capital letter, 1 number , 1 special character",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setErrorMessage("Password must 8 digit long, 1 capital letter, 1 number , 1 special character")
    }
    else if (inputValue.password !== inputValue.confirmPassword) {
      toast.warn("password mismatch", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      toast.warn("Email is not correct", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log('inputValue in sign up component',inputValue);
      axios.post(`${User_Api_Url}/signup`,inputValue,addToken ).then(resp=>{
        // console.log(resp,"here is my response");
        if(resp.data.message==="user created successfully"){
          toast.success("OTP sent successfully");
          localStorage.setItem("userId", resp.data.newUser._id)
          setOtpModalOpen(true);
        }else{
          swal({
            title: "",
            text: "User Allready Registered",
            icon: "error",
            dangerMode: true,
          })
        }
      }).catch(()=>{
        swal({
          title: "",
          text: "User Not Created",
          icon: "error",
          dangerMode: true,
        })
      })
      // setinputValue({
      //   username: "",
      //   email: "",
      //   password: "",
      //   confirmPassword: "",
      //   country: "",
      //   mobile:false,
      //   tab:false,
      //   desktop:false
      // });
    }
  };
  // setErrorMessage("Example error message!")
  return (
    <div className="signupContainer">
      <div className="imageContainer">
        <img
          src="https://icdn.video/sites/all/themes/ctv_theme/logo.png"
          alt=""
        />
      </div>

      <form onSubmit={handleSubmit} className="formContainer">
        <h3>Sign Up</h3>
        <ToastContainer />
        <div className="form-group">
          <label htmlFor="exampleInputUsername">Username</label>
          <input
            type="text"
            onChange={datafield}
            className="form-control"
            id="exampleInputUsername"
            name="username"
            placeholder="Enter username"
            value={inputValue.username}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email Address</label>
          <span className="secureIcone">
          <Tooltip title="We'll never share your email with anyone else.">   
              <PrivacyTipIcon color="primary" fontSize="small" />
          </Tooltip>
          </span>
          <input
            type="email"
            className="form-control"
            onChange={datafield}
            name="email"
            id="exampleInputEmail1"
            placeholder="Enter email"
            value={inputValue.email}
            required
          />
          {/* <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small> */}
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            onChange={datafield}
            name="password"
            id="exampleInputPassword1"
            placeholder="Password"
            value={inputValue.password}
            required
          />
          {errorMessage && <div className="error"> {errorMessage} </div>}
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword2">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            onChange={datafield}
            name="confirmPassword"
            id="exampleInputPassword2"
            placeholder="Password"
            value={inputValue.confirmPassword}
            required
          />
          
        </div>
        <div>
          <label htmlFor="exampleInputCountry">Country</label>
          <select
            id="exampleInputCountry"
            className="form-control"
            name="country"
            placeholder="Country"
            onChange={changeHandler}
            required
          >
          <option selected disabled>--Pick a Country--</option>
          {
            data.map((item,index)=>{
             return (<option key={index} value={item}>{item}</option>)
            })
          }
          </select>
        </div>

        <div className="createNewAccount">
            <button type="submit" value="Submit" className="btn btn-primary">
              CREATE NEW ACCOUNT
            </button>
            <button className="btn btn-primary" onClick={()=>navigate('/')}>Login</button>
        </div>
      </form>
    
      <OtpModal
      inputValue={inputValue}
          otpModalOpen={otpModalOpen} 
          email={inputValue.email}
          handleOtpModalClose={handleOtpModalClose}
        />
    </div>
  );
};

export default Signup;
