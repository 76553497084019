import DevicesIcon from '@mui/icons-material/Devices';
import HistoryIcon from '@mui/icons-material/History';
import ConstructionIcon from '@mui/icons-material/Construction';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ListIcon from '@mui/icons-material/List';
import PeopleIcon from '@mui/icons-material/People';
const SidebarItems = [
  {
    name: "Devices",
    route: "/user/devices",
    role: "user",
    icon: <DevicesIcon fontSize="small" />,
    modal:false
  },
  {
    name: "History",
    route: "/user/history",
    role: "user",
    icon: <HistoryIcon fontSize="small" />,
    modal:false
  },
  {
    name: "User List",
    route: "/admin/user_list",
    role: "admin",
    icon: <PeopleIcon fontSize="small" />,
    modal:false
  },
  {
    name: "Subscription List",
    route: "/admin/subscription_List",
    role: "admin",
    icon: <ListIcon fontSize="small" />,
    modal:false
  },
  {
    name: "Add Subscription",
    route: "",
    role: "admin",
    icon: <SubscriptionsIcon fontSize='small' />,
    modal:"add_sub"
  },
  {
    name: "Update Password",
    route: "",
    role: "admin",
    icon: <ConstructionIcon fontSize="small" />,
    modal:"password_admin"
  },
  {
    name: "Update Password",
    route: "",
    role: "user",
    icon: <ConstructionIcon fontSize="small" />,
    modal:"password_user"
  },
  {
    name: "Upgrade Subscription",
    route: "",
    role: "user",
    icon: <SubscriptionsIcon fontSize='small' />,
    modal:"update_sub"
  },
];

export default SidebarItems;
