import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { Get_All_User_Url } from "../../URL/url";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { useNavigate } from "react-router-dom";
import EditSubsModal from "./EditSubs Modal/EditSubsModel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import addToken from '../Login/addToken';

function User_List(props) {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [userId, setUserId] = useState("");
  const getUserList = () => {
    axios
      .get(Get_All_User_Url + "/getAllUser", 
        addToken()
      )
      .then((resp) => {
        const mapData = resp.data.user.map((items) => {
          // let myIndex = items.deviceDescription.indexOf("on");
          return {
            User_Email: items?.email,
            User_Id: items?._id,
            Plan: items?.subscriptionPlan,
            Domain_Name: items?.domainName
          };
        });
        setUserDetails(mapData);
      });
  };

  useEffect(() => {
    getUserList();
  }, []);

  const userColumns = [
    {
      name: "User_Id",
      label: "User Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Domain_Name",
      label: "Domain Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "User_Email",
      label: "User Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Plan",
      label: "Plan",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "User Devices",
      label: "USER DEVICES",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // options.onRowsDelete()
          return (
            <IconButton
              onClick={() => {
                localStorage.setItem("subcription_user", tableMeta.rowData[2]);
                navigate(`/admin/user_list/${tableMeta.rowData[0]}`);
              }}
              color="primary"
              aria-label="edit Subscription"
            >
              <VisibilityIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: "Delete",
      label: "DELETE",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              onClick={() => {
                axios
                  .delete(`${Get_All_User_Url}/user/${tableMeta.rowData[0]}`,addToken())
                  .then(() => {
                    toast.success("User deleted successfully!");
                    setUserId(tableMeta.rowData[1]);
                    getUserList();
                  })
                  .catch((error) => {
                    toast.error(`Something wrong 404`);
                  });
              }}
              color="error"
              aria-label="edit Subscription"
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const handleRowClick = (rowData, rowMeta) => {
    localStorage.setItem("subcription_user", rowData[0]);
    console.log("clicked!", rowData);
    navigate(`/admin/user_list/${rowData[1]}`);
    // /admin/user_list/:id
  };
  const options = {
    filterType: "dropdown",
    // selectableRowsOnClick: true,
    displayRowCheckbox: false,
    selectableRows: "single",
    // onRowClick: handleRowClick,
    onRowsDelete: (rowsDeleted, newData) => {
      console.log("rowsDeleted", userDetails);
      console.log(rowsDeleted.data, "were deleted!");
      const finalVal = rowsDeleted.data.map((items) => {
        return userDetails[items.dataIndex].User_Id;
      });
      console.log("finalval", finalVal.toString());
      axios
        .delete(`${Get_All_User_Url}/user/${finalVal.toString()}`,addToken())
        .then(() => {
          toast.success("User deleted successfully!");
        })
        .catch((error) => {
          toast.error(`Something wrong 404`);
        });
    },
  };

  return (
    <>
      <Layout role="admin">
        <MUIDataTable
          title={"User List"}
          data={userDetails}
          columns={userColumns}
          options={options}
        />
      </Layout>
      <ToastContainer />
    </>
  );
}

export default User_List;
