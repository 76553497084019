import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { Subscription_Api_Url } from "../../URL/url";
import EditSubsModal from "./EditSubs Modal/EditSubsModel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import addToken from '../Login/addToken';


function Subscription_list(props) {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = "";
  const [selectedData, setSelectedData] = useState({});

  const getSubscriptionList = ()=>{
    axios.get(Subscription_Api_Url + "subscription",addToken()).then((resp) => {
      // console.log(resp.data, "Get All Subscription");
      const mapData = resp.data.data.map((items) => {
        return {
          Subscription_type: items?.subscriptionPlan,
          Mobile: items?.mobile,
          Tablet: items?.tablet,
          Desktop: items?.desktop,
          Amount: items?.amount,
          Subscription_id: items?._id,
          planDuration: items.planDuration,
          NumberOfConnectionAllow: items.NumberOfConnectionAllow,
          NumberOfRoomAllow: items.NumberOfRoomAllow
        };
      });
      setSubscriptionList(mapData)
    });
  }

  useEffect(() => {
    getSubscriptionList()
  },[]);
  const columns = [
    {
      name: "Subscription_type",
      label: "Subscription Type",
      options: {
        filter: true,
        headerName: "number",
        sort: false,
      },
    },
    {
      name: "Mobile",
      label: "Mobile",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Tablet",
      label: "Tablet",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Desktop",
      label: "Desktop",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "planDuration",
      label: "Plan Duration",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "NumberOfConnectionAllow",
      label: "Allowed Connenction",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "NumberOfRoomAllow",
      label: "Allowed Rooms",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Edit",
      label: "Edit",
      options: {
        filter:true,
        sort:false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
              <IconButton onClick={()=>{
                setOpen(true);
                setSelectedData(subscriptionList[tableMeta.rowIndex]);
              }} color="error" aria-label="edit Subscription">
                <EditIcon />
              </IconButton>
          );
          }
      }
    },
    {
      name: "Delete",
      label: "Delete",
      options: {
			  filter: true,
        sort: false,
			  customBodyRender: (value, tableMeta, updateValue) => {
				return (
            <IconButton onClick={()=>{
              axios.delete(`${Subscription_Api_Url}/subscription/${subscriptionList[tableMeta.rowIndex].Subscription_id}`,addToken())
              .then(() => {
                toast.success("Subscription deleted successfully!");
                getSubscriptionList()
              })
              .catch((error) => {
                toast.error(`Something wrong 404`);
              });
            }} color="error" aria-label="delete Subscription">
              <DeleteIcon />
            </IconButton>
				);
			  }
			},
    },
  ];
  // const handleOpen = () => {
  //     setOpen(true);
  //   };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (rowData, rowMeta) => {
    setOpen(true);
    setSelectedData(subscriptionList[rowMeta.dataIndex]);
  };

  const options = {
    filterType: "checkbox",
    // selectableRowsOnClick: true,
    selectableRows: "single",
  };
  return (
    <>
      <Layout role="admin">
        <MUIDataTable
          title={"Subscription List"}
          data={subscriptionList}
          columns={columns}
          options={options}
        />
        <EditSubsModal
          open={open}
          handleOpen={open}
          handleClose={handleClose}
          data={selectedData}
        />
      </Layout>
      <ToastContainer />
    </>
  );
}

export default Subscription_list;
