import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Layout from "../../components/Layout/Layout";
import { Device_Api_Url } from "../../URL/url";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import addToken from "../Login/addToken";

function Page2(props) {
  const [userDeviceHistory, setUserDeviceHistory] = useState([])

  const getHistory = ()=>{
    const userId = localStorage.getItem('userId')
      axios.get(Device_Api_Url+'device/deviceList/'+ userId,addToken()).then((resp)=>{
          console.log(resp.data, "Get particular Devices")
          const mapData = resp.data.map(items=>{
              return {
                Device_Name: items?.deviceName,
                Device_Details: items?.deviceDescription,
                Device_IP: items?.ipAddress,
                Device_Logins: items?.numberOfLogin,
                Device_Location: items?.location,
                Operating_System: items?.os,
                numberOfLogin:items?.numberOfLogin,
                Device_id: items?._id
              }
           })
           console.log(mapData,"map") 
           setUserDeviceHistory(mapData)
      })
  }
  useEffect(()=>{
    getHistory()
  },[])
  const columns = [
    {
      name: "Device_Name",
      label: "Device Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_Details",
      label: "Device Details",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_IP",
      label: "Device IP",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Operating_System",
      label: "Operating System",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "numberOfLogin",
      label: "Device Logins",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Device_Location",
      label: "Device Location",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Delete",
      label: "Delete",
      options: {
			  filter: true,
        sort: false,
			  customBodyRender: (value, tableMeta, updateValue) => {
				return (
            <IconButton onClick={()=>{
              // console.log('k',userDeviceHistory[tableMeta.rowIndex]);
              axios.delete(`${Device_Api_Url}/device/${userDeviceHistory[tableMeta.rowIndex].Device_id}`,addToken()).then(()=>{
                toast.success('Device deleted successfully!');
                getHistory()
              }).catch((error)=>{
                toast.error(`Something wrong 404`);
              })
            }} color="error" aria-label="edit Subscription">
              <DeleteIcon />
            </IconButton>
				);
			  }
			},
    },

  ];


  const options = {
    filterType: 'checkbox',
    selectableRows: 'single',
    onRowsDelete: (rowsDeleted, newData) => {
      rowsDeleted.data.map(items => {
        return userDeviceHistory[items.dataIndex].User_Id
      })
    },
  }
  
  return (
    <>
    <Layout role="user">
      <MUIDataTable
        title={"History"}
        data={userDeviceHistory}
        columns={columns}
        options={options}
      />
    </Layout>
    <ToastContainer />
    </>
  );
}

export default Page2;
