import './Subscription.styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Subscription_Api_Url } from '../../URL/url';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Subscription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [userId,setUserId] = useState("")
  useEffect(() => {
    // const userId = localStorage.getItem('userId');
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',userId)
    axios.get(Subscription_Api_Url + 'subscription').then((resp) => {
      console.log(resp.data.data, 'Get All Subscription');
      setUserSubscriptionList(resp.data.data);
    }).then(()=>{
      setUserId(localStorage.getItem('userId'))
    });
  }, []);

  const handleSubmit = (id) => {
    const data = {
      subscriptionId: id,
    };
    localStorage.removeItem('signin_user');
    if (!userId) {
      toast.warn("Please Select Any Subscription");
    } else {
      axios
        .post(Subscription_Api_Url + `/subscription/upgrade/${localStorage.getItem('userId')}`, data)
        .then(() => {
          toast.success("User Plan Upgraded Susscessfuly");
          navigate('/user/devices')
        })
        .catch((error) => {
          toast.error(`Something wrong 404`);
        });
    }
  };


  const handleSubscription = (id) => {
    const userId = localStorage.getItem('userId');
    const data = {
      userId: userId,
      subscriptionId: id,
    };
    axios.post(Subscription_Api_Url + 'subscription/add', data).then((resp) => {
      console.log(resp.data.data, 'Get All Subscription');
      setUserSubscriptionList(resp.data.data);
      navigate('/payment', { state: location.state });
    });
  };
  return (
    <div>
      <div className="" style={{ paddingTop: '40px', height: '100vh' }}>
        <div className="row">
          {/* <h1>hii</h1> */}
          {/* <!-- Purple Table --> */}
          {userSubscriptionList &&
            userSubscriptionList?.map((items) => {
              return (
                <div className="col-md-4">
                  <div className="pricing-table purple">
                
                    <h5 className='pricing-table-title'>{items.subscriptionPlan}</h5>
                 
                    <div className="pricing-features">
                      <div className="feature">
                        <div className="paraName">
                          <span className="titleName">Mobile</span>
                          <span className="titleValue">{items.mobile}</span>
                        </div>
                        <div className="paraName">
                          <span className="titleName">Desktop</span>
                          <span className="titleValue">{items.desktop}</span>
                        </div>
                        <div className="paraName">
                          <span className="titleName">Amount</span>
                          <span className="titleValue">{items.amount}</span>
                        </div>
                        <div className="paraName">
                          <span className="titleName">Plan Duration</span>
                          <span className="titleValue">{items.planDuration}</span>
                        </div>
                        <div className="paraName">
                          <span className="titleName">Allowed Connenction</span>
                          <span className="titleValue">{items.NumberOfConnectionAllow}</span>
                        </div>
                        <div className="paraName">
                          <span className="titleName">Allowed Rooms</span>
                          <span className="titleValue">{items.NumberOfRoomAllow}</span>
                        </div>
                      </div>
                    </div>
                 
                    <div className="price-tag">
                      <span className="symbol"></span>
                      <span className="amount">{items.amount}</span>
                      <span className="after">/month</span>
                    </div>
                   
                    {localStorage.getItem('signup_user') ? <span
                      onClick={() => {
                        handleSubscription(items._id);
                      }}
                      className="price-button"
                    >
                      Subscribe
                    </span> : <span
                      onClick={() =>{
                        handleSubmit(items._id)
                        console.log("id>>>>>>>>>>>>>>>>>",items._id)
                      }}
                      className="price-button"
                    >
                      Upgrade
                    </span>}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
