import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import "./UpdateSubscription.css";
import axios from "axios";
import { Subscription_Api_Url, User_Api_Url } from "../../../URL/url";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import addToken from "../../Login/addToken";

const style = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "18px",
  boxShadow: 24,
  p: 4,
};

export default function UpdateSubscription(props) {
  const [request, setRequest] = useState({
    subscriptionId: "",
  });
  const [userId, setUserId] = useState("");

  const [userSubscription, setUserSubscription] = useState([]);

  const navigate = useNavigate()


  useEffect(() => {
    const user_Id = localStorage.getItem("userId");
    setUserId(userId);
    axios
      .get(
        Subscription_Api_Url +
          `/subscription/getSubscription/${localStorage.getItem("userId")}`,addToken()
      )
      .then((resp) => {
        setUserSubscription(resp.data.userSubscriptionDetail.subscriptionId);
      });
  }, []);

  const handleOption = (e) => {
    const subId = e.target.value;
    setRequest({ ...request, subscriptionId: subId });
  };

  // const handleSubmit = (props) => {
  //   if (!userId) {
  //     toast.warn("Please Select Any Subscription");
  //   } else {
  //     axios
  //       .patch(Subscription_Api_Url + `subscription/upgrade/${userId}`, request)
  //       .then(() => {
  //         toast.success("User Plan Upgraded Susscessfuly");
  //       })
  //       .catch((error) => {
  //         toast.error(`Something wrong 404`);
  //       });
  //   }
  // };



  return (
    <div>
      <Modal
        open={props.updateSubModelOpen}
        onClose={props.handleUpdateSubClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="subsContent">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <div className="headingContainer"></div> */}
              <div className="subs_Heading">Upgrade Subscription</div>
              <div style={{ marginTop: "5px", marginLeft: "20px" }}>
                <CloseIcon
                  fontSize="large"
                  onClick={props.handleUpdateSubClose}
                  className="closeIcon"
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="pricing-table purple">
                <h5 className="pricing-table-title">
                  {userSubscription.subscriptionPlan}
                </h5>
                <div className="pricing-features">
                  <div className="feature">
                    <div className="paraName">
                      <span className="titleName">Mobile</span>
                      <span className="titleValue">
                        {userSubscription.mobile}
                      </span>
                    </div>
                    <div className="paraName">
                      <span className="titleName">Desktop</span>
                      <span className="titleValue">
                        {userSubscription.desktop}
                      </span>
                    </div>
                    <div className="paraName">
                      <span className="titleName">Amount</span>
                      <span className="titleValue">
                        {userSubscription.amount}
                      </span>
                    </div>
                    <div className="paraName">
                      <span className="titleName">Plan Duration</span>
                      <span className="titleValue">
                        {userSubscription.planDuration}
                      </span>
                    </div>
                    <div className="paraName">
                      <span className="titleName">Allowed Connenction</span>
                      <span className="titleValue">
                        {userSubscription.NumberOfConnectionAllow}
                      </span>
                    </div>
                    <div className="paraName">
                      <span className="titleName">Allowed Rooms</span>
                      <span className="titleValue">
                        {userSubscription.NumberOfRoomAllow}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="price-tag">
                  <span className="symbol"></span>
                  <span className="amount">{userSubscription.amount}</span>
                  <span className="after">/month</span>
                </div>

                <Link to="/subscription">
                <span
                  className="price-button"
                >
                  Upgrade
                </span>
                </Link>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
