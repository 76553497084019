import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import "./AddSubsModal.css";
import axios from "axios";
import { TextField } from "@mui/material";
import { Subscription_Api_Url } from "../../../URL/url";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import addToken from "../../Login/addToken";

const style = {
  display: "flex",
  // justifyContent: "space-around",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "18px",
  boxShadow: 24,
  p: 4,
  width: "40%",
};

export default function AddSubsModal(props) {
  const [request, setRequest] = useState({
    subscriptionPlan: "",
    amount: "",
    mobile: "",
    tablet: "",
    desktop: "",
    planDuration: "",
    NumberOfConnectionAllow: "",
    NumberOfRoomAllow: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRequest((request) => {
      return {
        ...request,
        [name]: value,
      };
    });
  };

  const handleSubmit = (props) => {
    if (!request.subscriptionPlan) {
      toast.warn("Please Fill Fields!");
    } else {
      axios
        .post(Subscription_Api_Url + "subscription", request,addToken())
        .then((resp) => {
          console.log("Add subscription successfully", resp);
          toast.success("Add subscription successfully");
          props.handleSubsModalClose();
        })
        .catch((err) => {
          toast.error(`Something wrong 404`);
        });
    }
  };
  return (
    <>
      <div>
        <Modal
          open={props.subsModalOpen}
          onClose={props.handleSubsModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="addSubsContent">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="subsHeading">Add Subscription</div>

                <IconButton
                  className="closeIcon-btn"
                  color="primary"
                  aria-label="close"
                >
                  <CloseIcon onClick={props.handleSubsModalClose} />
                </IconButton>
              </div>

              <form className="selectFields">
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Plan"
                  onChange={handleChange}
                  name="subscriptionPlan"
                  value={request.subscriptionPlan}
                  required
                />
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Amount"
                  onChange={handleChange}
                  name="amount"
                  value={request.amount}
                  required
                />
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Mobile"
                  onChange={handleChange}
                  name="mobile"
                  value={request.mobile}
                  required
                />
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Tablet"
                  onChange={handleChange}
                  name="tablet"
                  value={request.tablet}
                  required
                />
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Desktop"
                  onChange={handleChange}
                  name="desktop"
                  value={request.desktop}
                  required
                />
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Plan Duration"
                  onChange={handleChange}
                  name="planDuration"
                  value={request.planDuration}
                  required
                />
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Number Of Connection Allow"
                  onChange={handleChange}
                  name="NumberOfConnectionAllow"
                  value={request.NumberOfConnectionAllow}
                  required
                />
                <TextField
                  className=""
                  id="demo-helper-text-misaligned-no-helper"
                  label="Number Of Room Allow"
                  onChange={handleChange}
                  name="NumberOfRoomAllow"
                  value={request.NumberOfRoomAllow}
                  required
                />
              </form>
              <div className="addSub__btnDiv">
                <button className="subsBtn" onClick={() => handleSubmit(props)}>
                  Add
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
}
