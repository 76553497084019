import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import "./EditSubsModal.css";
import axios from "axios";
import { TextField } from "@mui/material";
import { Subscription_Api_Url } from "../../../URL/url";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import addToken from "../../Login/addToken";

const style = {
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "18px",
  boxShadow: 24,
  p: 4,
  width: "40%"
};

function EditSubsModal({ handleClose, data, open }) {
  const [request, setRequest] = useState({
    subscriptionPlan: "",
    amount: "",
    mobile:"",
    tablet:"",
    desktop:"",
    planDuration: "",
    NumberOfConnectionAllow: "",
    NumberOfRoomAllow:""
  });
  const [rowId, setRowId] = useState("")
  const [close, setClose] = useState(false)
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRequest((request) => {
      return {
        ...request,
        [name]: value,
      };
    });
    console.log("request:", request);
  };

  const modelClose = () => {
    setClose(false);
    console.log(close);
    console.log("you clicked");
  };

  const handleSubmit = (e) => {
    const { name, value } = e.target;
    setRequest((request) => {
      return {
        ...request,
        [name]: value,
      };
    });
    console.log("request:>>>", request);
    axios.put(Subscription_Api_Url+'subscription/'+ rowId, request,addToken()).then((resp) => {
      console.log("Edit subscription successfully", resp)
    })
    handleClose()
  };

  useEffect(() => {
    setRowId(data.Subscription_id)
    setRequest({
      subscriptionPlan: data.Subscription_type,
      amount: data.Amount,
      mobile: data.Mobile,
      tablet: data.tablet,
      desktop: data.Desktop,
      planDuration: data.planDuration,
      NumberOfConnectionAllow: data.NumberOfConnectionAllow,
      NumberOfRoomAllow: data.NumberOfRoomAllow
    })
  },[data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",  // Set your width here
            },
          },
        }}
      >
        <Box sx={style}>
          <div className="editSubContent">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="subsHeading">Edit Subscription</div>
              <IconButton color="primary" aria-label="close">
                <CloseIcon onClick={handleClose} />
              </IconButton>
            </div>

            <form className="selectFields">
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Plan" onChange={handleChange} name="subscriptionPlan" value={request.subscriptionPlan} />
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Amount" onChange={handleChange} name="amount" value={request.amount} />
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Mobile" onChange={handleChange} name="mobile" value={request.mobile} />
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Tablet" onChange={handleChange} name="tablet" value={request.tablet} />
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Desktop" onChange={handleChange} name="desktop" value={request.desktop} />
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Plan Duration" onChange={handleChange} name="planDuration" value={request.planDuration} />
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Number Of Connection Allow" onChange={handleChange} name="NumberOfConnectionAllow" value={request.NumberOfConnectionAllow} />
              <TextField className="" id="demo-helper-text-misaligned-no-helper" label="Number Of Room Allow" onChange={handleChange} name="NumberOfRoomAllow" value={request.NumberOfRoomAllow} />
            </form>
            <div className="editSub__btnDiv">
              <button className="subsBtn" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default EditSubsModal;
